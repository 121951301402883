<template>
  <div class="p-4 card">
    <form class="form-container">
      <div class="row">
        <div class="col-12">
          <h4 class="mt-0 mb-2">
            {{ $t('auto-dialer.campaign-details.title') }}
          </h4>
        </div>
        <div class="mb-2 col-12">
          <Field
            :label="$t('auto-dialer.campaign-details.form.name.label')"
            :placeholder="
              $t('auto-dialer.campaign-details.form.name.placeholder')
            "
            :info="$t('auto-dialer.campaign-details.form.name.info')"
            v-model="model.name"
            disabled
          />
        </div>
        <div class="mb-2 col-12 col-lg-4 col-md-6">
          <Field
            :label="
              $t(
                'auto-dialer.campaign-details.form.waiting-customer-count.label'
              )
            "
            :info="
              $t(
                'auto-dialer.campaign-details.form.waiting-customer-count.info'
              )
            "
          >
            <b-form-input
              class="border"
              v-model="model.waitingCustomerCount"
              type="number"
              min="1"
              @keydown="onlyAcceptNumbers"
              readonly
            />
          </Field>
        </div>

        <div class="mb-2 col-12 col-lg-4 col-md-6">
          <Field
            :label="$t('auto-dialer.campaign-details.form.trials-count.label')"
            :info="$t('auto-dialer.campaign-details.form.trials-count.info')"
          >
            <b-form-spinbutton
              class="border"
              v-model="model.trialsCount"
              min="1"
              max="10"
              wrap
              placeholder="-"
              readonly
            />
          </Field>
        </div>

        <div class="mb-2 col-12 col-lg-4 col-md-6">
          <Field
            :label="$t('auto-dialer.campaign-details.form.wrap-up-time.label')"
            :info="$t('auto-dialer.campaign-details.form.wrap-up-time.info')"
          >
            <b-form-input
              class="border"
              v-model="model.wrapUpTime"
              type="number"
              min="10"
              @keydown="onlyAcceptNumbers"
              readonly
            />
          </Field>
        </div>

        <div class="mb-2 col-12 col-lg-4 col-md-6">
          <Field
            :label="
              $t(
                'auto-dialer.campaign-details.form.delay-minutes-between-trials.label'
              )
            "
            :info="
              $t(
                'auto-dialer.campaign-details.form.delay-minutes-between-trials.info'
              )
            "
          >
            <b-form-input
              class="border"
              v-model="model.delayMinutesBetweenTrials"
              type="number"
              min="5"
              @keydown="onlyAcceptNumbers"
              readonly
            />
          </Field>
        </div>

        <div class="col-12 row">
          <div class="mb-2 col-4">
            <b-form-checkbox v-model="model.hideCallerInfo" disabled>
              {{ $t('auto-dialer.campaign-details.form.hideCallerInfo.label') }}
            </b-form-checkbox>
          </div>
          <div class="mb-2 col-4">
            <b-form-checkbox v-model="model.agentCanLogoutAndRejoin" disabled>
              {{ $t('auto-dialer.update.form.agentCanLogoutAndRejoin.label') }}
            </b-form-checkbox>
          </div>
        </div>
      </div>
      <hr class="w-100" />
      <div class="row">
        <div class="col-12">
          <h4 class="mt-0 mb-2">
            {{ $t('auto-dialer.campaign-details.form.time') }}
          </h4>
        </div>
        <div class="mb-2 col-12 col-lg-6">
          <Field
            :label="$t('auto-dialer.campaign-details.form.duration-type.label')"
            :info="$t('auto-dialer.campaign-details.form.duration-type.title')"
          >
            <b-form-select
              class="border"
              :options="durationTypes"
              v-model="model.durationType"
              :allowEmpty="false"
              disabled
            />
          </Field>
        </div>
        <div class="col-12 col-lg-6">
          <Field
            :label="$t('auto-dialer.campaign-details.form.max-wait-time.label')"
            :info="$t('auto-dialer.campaign-details.form.max-wait-time.title')"
          >
            <b-form-input
              class="border"
              v-model="model.maxWaitTime"
              type="number"
              min="100"
              max="10000"
              @keydown="onlyAcceptNumbers"
              readonly
            />
          </Field>
        </div>

        <div
          class="mb-2 col-12 col-lg-4 col-md-6"
          v-if="model.durationType === 'time-limited'"
        >
          <Field
            :label="$t('auto-dialer.campaign-details.form.from-time.label')"
            :info="$t('auto-dialer.campaign-details.form.from-time.info')"
          >
            <b-form-input
              class="border"
              v-model="model.fromTime"
              type="time"
              readonly
            />
          </Field>
        </div>
        <div
          class="mb-2 col-12 col-lg-4 col-md-6"
          v-if="model.durationType === 'time-limited'"
        >
          <Field
            :label="$t('auto-dialer.campaign-details.form.to-time.label')"
            :info="$t('auto-dialer.campaign-details.form.to-time.info')"
          >
            <b-form-input
              class="border"
              v-model="model.toTime"
              type="time"
              readonly
            />
          </Field>
        </div>
        <div
          class="mb-2 col-12 col-lg-4 col-md-6"
          v-if="model.durationType === 'time-limited'"
        >
          <Field
            :label="$t('auto-dialer.campaign-details.form.time-zone.label')"
            :info="$t('auto-dialer.campaign-details.form.time-zone.info')"
          >
            <b-form-select
              class="border"
              :options="timezones"
              v-model="model.timezone"
              :allowEmpty="false"
              disabled
            />
          </Field>
        </div>
      </div>
      <hr class="w-100" />
      <div class="row">
        <div class="col-12">
          <h4 class="mt-0 mb-2">
            {{ $t('auto-dialer.campaign-details.form.sound') }}
          </h4>
        </div>
        <div class="mb-2 col-12">
          <b-form-checkbox v-model="model.hasAnnouncement" disabled>
            {{ $t('auto-dialer.campaign-details.form.hasAnnouncement.label') }}
          </b-form-checkbox>
        </div>

        <div class="mb-2 col-12 col-md-6">
          <Field
            :label="$t('auto-dialer.campaign-details.form.music-on-hold.label')"
            :info="$t('auto-dialer.campaign-details.form.music-on-hold.info')"
          >
            <DropzoneFile
              v-if="model.loopSoundFileName && !model.loopSoundFile"
              :file="{
                name: model.loopSoundFileName
              }"
              readonly
            />
            <Dropzone
              v-else
              class="mb-2"
              :options="{
                paramName: 'file',
                acceptedFiles: '.mp3',
                maxFiles: 1
              }"
              v-model="model.loopSoundFile"
              disabled
            >
              <div class="dropzone-custom-content">
                <h3 class="dropzone-custom-title">
                  {{
                    $t('auto-dialer.campaign-details.form.music-on-hold.title')
                  }}
                </h3>
                <div class="subtitle">
                  {{
                    $t(
                      'auto-dialer.campaign-details.form.music-on-hold.subtitle'
                    )
                  }}
                </div>
                <div class="description">
                  {{
                    $t(
                      'auto-dialer.campaign-details.form.music-on-hold.description'
                    )
                  }}
                </div>
              </div>
            </Dropzone>
          </Field>
        </div>

        <div class="mb-2 col-12 col-md-6" v-if="model.hasAnnouncement">
          <Field
            :label="$t('auto-dialer.campaign-details.form.announcement.label')"
            :info="$t('auto-dialer.campaign-details.form.announcement.info')"
          >
            <DropzoneFile
              v-if="model.mainSoundFileName && !model.mainSoundFile"
              :file="{
                name: model.mainSoundFileName
              }"
              readonly
            />

            <Dropzone
              v-else
              class="mb-2"
              :options="{
                paramName: 'file',
                acceptedFiles: '.mp3',
                maxFiles: 1
              }"
              v-model="model.mainSoundFile"
            >
              <div class="dropzone-custom-content">
                <h3 class="dropzone-custom-title">
                  {{
                    $t('auto-dialer.campaign-details.form.announcement.title')
                  }}
                </h3>
                <div class="subtitle">
                  {{
                    $t(
                      'auto-dialer.campaign-details.form.announcement.subtitle'
                    )
                  }}
                </div>
                <div class="description">
                  {{
                    $t(
                      'auto-dialer.campaign-details.form.announcement.description'
                    )
                  }}
                </div>
              </div>
            </Dropzone>
          </Field>
        </div>
      </div>

      <!-- <hr class="w-100" />
      <div class="row">
            <div class="col-12">
              <b-form-checkbox v-model="model.recording.enabled">
                <h4 class="mt-0 mb-2">
                  {{ $t('auto-dialer.campaign-details.form.recording.title') }}
                </h4>
              </b-form-checkbox>
            </div>

            <div
              class="align-items-center col-12 row"
              v-if="model.recording.enabled"
            >
              <div class="col-12 col-lg-6">
                <Field
                  :label="$t('auto-dialer.campaign-details.form.recording.format.label')"
                >
                  <b-form-select
                    class="border"
                    v-model="model.recording.format"
                    :options="[
                      { text: 'MP3', value: 'mp3' },
                      { text: 'GSM', value: 'gsm' },
                      { text: 'WAV', value: 'wav' },
                      { text: 'WAV49', value: 'wav49' }
                    ]"
                  />
                </Field>
              </div>
              <div class="col-12 col-lg-6">
                <b-form-checkbox v-model="model.recording.beep">
                  {{ $t('auto-dialer.campaign-details.form.recording.beep.label') }}
                </b-form-checkbox>
              </div>
              <div class="col-12 col-lg-6">
                <Field
                  :label="
                    $t('auto-dialer.campaign-details.form.recording.max-duration.label')
                  "
                >
                  <b-form-input
                    class="border"
                    v-model="model.recording.maxDuration"
                    type="number"
                    min="0"
                    max="10000"
                    @keydown="onlyAcceptNumbers"
                  />
                </Field>
              </div>
            </div>
          </div> -->

      <hr class="w-100" />
      <div class="callers-container row">
        <div class="col-12">
          <h4 class="mt-0 mb-2">
            {{ $t('auto-dialer.campaign-details.form.callers.title') }}
          </h4>
        </div>

        <div
          v-for="(caller, idx) in model.callers"
          :key="caller.id"
          class="align-items-start col-12 row"
        >
          <div class="col-11 row">
            <div class="col-12 col-md-6">
              <Field
                :label="
                  $t('auto-dialer.campaign-details.form.destinations.label')
                "
                :info="
                  $t('auto-dialer.campaign-details.form.destinations.info')
                "
              >
                <b-form-select
                  class="border"
                  @change="onCountryChange($event, idx, caller.id)"
                  v-model="model.callers[idx].destination"
                  disabled
                >
                  <b-form-select-option
                    v-for="country in countries"
                    :key="country.code"
                    :value="country.code"
                    :disabled="
                      model.callers[idx].destination !== country.code &&
                        selectedCountries.includes(country.code)
                    "
                  >
                    {{ country.emoji }} {{ country.name }}
                  </b-form-select-option>
                </b-form-select>
              </Field>
            </div>

            <div class="col-12 col-md-6">
              <Field
                :label="
                  $t('auto-dialer.campaign-details.form.caller-ids.label')
                "
                :info="$t('auto-dialer.campaign-details.form.caller-ids.info')"
              >
                <b-form-select
                  class="border"
                  v-model="model.callers[idx].callerNumber"
                  :options="callerNumbers"
                  disabled
                />
              </Field>
            </div>
          </div>
        </div>
      </div>

      <hr class="w-100" />
      <div class="agents row">
        <div class="col-12">
          <h4 class="mt-0 mb-2">
            {{ $t('auto-dialer.create.step1.form.agents.title') }}
          </h4>
        </div>
        <div class="col-12">
          <Field
            :label="$t('auto-dialer.create.step1.form.agents.agent.label')"
            :info="$t('auto-dialer.create.step1.form.agents.agent.info')"
          >
            <Select
              searchable
              multiple
              :placeholder="
                $t('auto-dialer.create.step1.form.agents.agent.placeholder')
              "
              v-model="model.agents"
              :options="extensions"
              :preselectFirst="false"
              readonly
            />
          </Field>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Field from '../../../elements/Field.vue'
import { onlyAcceptNumbers } from '../../../utils/utils'
import timezones from '../../../constants/timezones.json'
import Dropzone from '../../../elements/Dropzone.vue'

import momentTimezone from 'moment-timezone'
import AutoDialerService from '../../../services/autoDialer.service'
import DropzoneFile from '../../../elements/DropzoneFile.vue'
import vocabService from '../../../services/vocab.service'
import ExtensionService from '../../../services/extension.service'

export default {
  name: 'CampaignDetails',
  components: {
    Field,
    Dropzone,
    DropzoneFile
  },
  computed: {
    timezones() {
      return timezones
    },
    durationTypes() {
      return [
        {
          text: this.$t(
            'auto-dialer.campaign-details.form.duration-type.options.time-limited'
          ),
          value: 'time-limited'
        },
        {
          text: this.$t(
            'auto-dialer.campaign-details.form.duration-type.options.agent-availability'
          ),
          value: 'agent-availability'
        }
      ]
    },
    soundModes() {
      return [
        {
          text: this.$t(
            'auto-dialer.campaign-details.form.sound-mode.options.no-sound'
          ),
          value: 'no-sound'
        },
        {
          text: this.$t(
            'auto-dialer.campaign-details.form.sound-mode.options.play-sound'
          ),
          value: 'play-sound'
        }
      ]
    }
  },
  data() {
    return {
      campaignId: this.$route.params.id,
      model: {
        name: '',
        durationType: 'time-limited',
        trialsCount: 1,
        wrapUpTime: 10,
        allowAgentAnswer: '',
        hasAnnouncement: false,
        mainSoundFile: null,
        loopSoundFile: null,
        waitingCustomerCount: 1,
        fromTime: '',
        toTime: '',
        maxWaitTime: 100,
        timezone: '',
        callers: [{ destination: '', callerNumber: '' }],
        recording: {
          enabled: false,
          format: 'mp3',
          beep: false,
          maxDuration: 0
        },
        callers: [{ destination: '', callerNumber: '' }],
        hideCallerInfo: false,
        agentCanLogoutAndRejoin: false,
        agents: []
      },
      countries: [],
      selectedCountries: [],
      callerNumbers: [],
      extensions: []
    }
  },
  methods: {
    // utils
    onlyAcceptNumbers(event) {
      onlyAcceptNumbers(event)
    },
    // api calls
    async getCountries() {
      const countries = await vocabService.getCountries()
      this.countries = countries
    },
    async getCallerNumbers() {
      const callerNumbers = await vocabService.getCallerIds()
      this.callerNumbers = callerNumbers.map(number => ({
        text: number,
        value: number
      }))
    },
    async getCampaignDetails() {
      try {
        const res = await AutoDialerService.getCampaign(this.$route.params.id)
        this.model = {
          ...this.model,
          ...res.data.campaign,
          agents: this.extensions.filter(ext =>
            res.data.campaign.assignedAgents.includes(+ext.ext)
          ),
          hasAnnouncement: !!res.data.campaign.mainSoundFileName
        }
      } catch (error) {
        const errorMessage = error.response
          ? error.response.data.message
          : error.message
        this.toast(errorMessage, {
          type: 'error'
        })
      }
    },
    async getExtensions() {
      try {
        const res = await ExtensionService.getAllExtensions()
        this.extensions = res.map(ext => ({
          ...ext,
          name: `${ext.name} (${ext.ext})`,
          id: ext.ext
        }))
      } catch (error) {
        this.toast(error.message, {
          type: 'error'
        })
      }
    }
  },
  async mounted() {
    this.getCallerNumbers()
    this.getCountries()
    await this.getExtensions()
    this.getCampaignDetails()
  }
}
</script>
